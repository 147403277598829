<template>
  <div>
    <b-row>
      <b-col
        v-for="(packageItem,key) in packages"
        :key="packageItem.id"
        class="text-center"
      >
        <b-card no-body>
          <b-card-body>
            <b-card-title class="mb-1">
              {{ packageItem.title }}
            </b-card-title>
            <b-card-sub-title class="mb-2">
              {{ packageItem.id === '1'? startupTitle + ' Raporu' : 'Tüm Raporlara Sınırsız Erişim' }}
            </b-card-sub-title>
            <div class="mb-2">
              <div class="font-weight-bolder">
                Paket İçeriği
              </div>
              <div>{{ packageItem.content }}</div>
            </div>
            <div class="font-weight-bolder">
              Kullanım Süresi
            </div>
            <div>
              {{ packageItem.duration }} {{ $t('Yıl') }}
            </div>
            <hr>
            <div>
              <div class="text-primary font-weight-bolder font-medium-4">
                {{ packageItem.amount | toCurrency }} ₺
              </div>
              <small class="text-muted">+ KDV</small>
            </div>
          </b-card-body>
          <b-card-footer v-if="startupId">
            <b-button
              v-if="!packageItem.disabled"
              variant="primary"
              block
              :disabled="packageItem.disabled"
              @click="addCart({id_startups: startupId, id_packages: packageItem.id},key)"
            >
              <FeatherIcon icon="ShoppingCartIcon" />
              Sepete Ekle
            </b-button>
            <b-button
              v-else
              variant="success"
              block
              to="/siparislerim/sepet"
            >
              <FeatherIcon icon="ShoppingCartIcon" />
              Sepet'e Git
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardSubTitle, BCardBody, BCardFooter, BButton,
} from 'bootstrap-vue'

export default {
  name: 'ViewPackages',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BButton,
    BCardFooter,
  },
  props: {
    startupTitle: {
      type: String,
      required: false,
      default: '',
    },
    startupId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showPackages: true,
    }
  },
  computed: {
    packages() {
      return this.$store.getters['appPackages/getPackages']
    },
    cart() {
      return this.$store.getters['cart/getCart']
    },
  },
  created() {
    this.getPackages()
  },
  methods: {
    getPackages() {
      this.$store.dispatch('appPackages/packagesData')
    },
    addCart(data, key) {
      this.$store.dispatch('cart/cartAddItem', {
        id_startups: data.id_startups,
        id_packages: data.id_packages,
      })
      this.packages[key].disabled = true
    },
  },
}
</script>

<style scoped>

</style>
